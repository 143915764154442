import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/InstallingSparkLayout.js";
import folderStructure from '../../images/installing-spark/html/folder-structure.png';
import bundledJs from '../../images/installing-spark/html/bundled-js.png';
import greenBg from '../../images/installing-spark/html/green-bg.png';
import helloSpark from '../../images/installing-spark/html/hello-spark.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <blockquote>
      <p parentName="blockquote">{`This guide is Part 1 in a 3-Part series and will walk through setting up your Spark
development environment. You can also check out `}<a parentName="p" {...{
          "href": "/installing-spark/html-installation"
        }}>{`Part 2 - Installation`}</a>{` and
`}<a parentName="p" {...{
          "href": "/installing-spark/html-add-components"
        }}>{`Part 3 - Adding Components`}</a>{`.`}</p>
    </blockquote>
    <h1>{`Part 1: Setting up Your Environment for an HTML Project`}</h1>
    <p>{`For instructions on setting up Spark in other environments, check out the guides for
`}<a parentName="p" {...{
        "href": "/installing-spark/angular"
      }}>{`Angular`}</a>{` and `}<a parentName="p" {...{
        "href": "/installing-spark/react"
      }}>{`React`}</a>{` development environments.`}</p>
    <p>{`Before you begin, make sure you `}<a href="https://www.npmjs.com/get-npm" target="_blank">{`install Node Package Manager`}</a>{`
(`}<inlineCode parentName="p">{`npm`}</inlineCode>{`).`}</p>
    <p>{`This is a tool that makes it easy to share and reuse
JavaScript code and to update the JavaScript code you’re sharing.`}</p>
    <p>{`Our `}<a href="https://github.com/sparkdesignsystem/spark-starter-html/" target="_blank">{`starter app examples`}</a>{` are also available for reference:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sparkdesignsystem/spark-starter-html/tree/kitchen-sink"
        }}>{`'kitchen-sink' branch`}</a>{` (Spark Installed with component examples.)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/sparkdesignsystem/spark-starter-html/tree/without-spark"
        }}>{`'without-spark' branch`}</a>{` (All of the prerequisites before Spark Installation.)`}</li>
    </ul>
    <div className="sprk-u-Measure" style={{
      "position": "relative",
      "paddingBottom": "56.25%",
      "height": "0"
    }}>
      <iframe style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }} src="https://www.youtube.com/embed/vo4BIUoYX28" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Installing Spark in an HTML Project Part 1/3 - Setting Up Project Environment"></iframe>
    </div>
    <h2>{`Create the Folder Structure`}</h2>
    <ol>
      <li parentName="ol">{`First you’ll need to make a new directory for your project, open it
and initialize `}<inlineCode parentName="li">{`npm`}</inlineCode>{`.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`mkdir spark-html

cd spark-html

npm init
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`When prompted, accept the default settings for `}<inlineCode parentName="li">{`npm init`}</inlineCode>{`. You
can always edit these later.`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Confirm that it's generated a `}<inlineCode parentName="li">{`package.json`}</inlineCode>{`. This file keeps track
of all the dependencies that your site needs to build and run.`}</li>
    </ul>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">
        <p parentName="li">{`Inside your `}<inlineCode parentName="p">{`spark-html`}</inlineCode>{` folder, create a `}<inlineCode parentName="p">{`src`}</inlineCode>{` folder and a `}<inlineCode parentName="p">{`dist`}</inlineCode>{` folder.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`src`}</inlineCode>{` folder, create a file called `}<inlineCode parentName="p">{`index.js`}</inlineCode>{` and and add this code:`}</p>
      </li>
    </ol>
    <ul>
      <li parentName="ul">{`A function that creates a div with text inside.`}</li>
      <li parentName="ul">{`Code that will add that div onto the page.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function component() {
  const element = document.createElement('div');
  element.innerHTML = 'Hello, Spark! JavaScript is working!';
  return element;
}

document.body.appendChild(component());
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`We do this because it's a way to check if we've hooked up our JavaScript correctly.`}</p>
    </blockquote>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`In the `}<inlineCode parentName="li">{`dist`}</inlineCode>{` folder, create a file called `}<inlineCode parentName="li">{`index.html`}</inlineCode>{` that will reference that `}<inlineCode parentName="li">{`index.js`}</inlineCode>{` file inside the `}<inlineCode parentName="li">{`<body>`}</inlineCode>{` tag.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html>
  <head>
      <title>Hello, Spark!</title>
  </head>
  <body>
    <script src="../src/index.js"></script>
  </body>
</html>
`}</code></pre>
    <p>{`You should now have the initial files for your new site. The folder
structure should look like this:`}</p>
    <div className="sprk-u-Measure">
  <img className="sprk-u-mbm" style={{
        "margin": "auto",
        "display": "block"
      }} src={folderStructure} alt="A dist folder containing index.html. A src folder containing index.js. A package.json in the root." />
    </div>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Open `}<inlineCode parentName="li">{`index.html`}</inlineCode>{` in a web browser. You should whatever text you set in your `}<inlineCode parentName="li">{`index.js`}</inlineCode>{` file.`}</li>
    </ol>
    <div className="sprk-u-Measure">
  <img className="sprk-u-mbm" style={{
        "margin": "auto",
        "display": "block"
      }} src={helloSpark} alt="Hello Spark!" />
    </div>
    <h2>{`Installing Webpack`}</h2>
    <a href="https://webpack.js.org/" target="_blank">Webpack</a> combines all the JavaScript needed to run Spark into one file.
    <ol>
      <li parentName="ol">{`To install Webpack, run this command:`}</li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`npm install webpack webpack-cli --save-dev
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`The `}<inlineCode parentName="p">{`--save-dev`}</inlineCode>{` flag indicates that these packages are required
to compile your website, but are not necessary to run the site
after compilation.`}</p>
    </blockquote>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`In your `}<inlineCode parentName="li">{`package.json`}</inlineCode>{` file, look for
the `}<inlineCode parentName="li">{`scripts`}</inlineCode>{` section. This is a list of custom commands that `}<inlineCode parentName="li">{`npm`}</inlineCode>{`
can run. Add a new command for building the site
with Webpack:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`"scripts": {
  "build": "webpack --mode='development'"
},
`}</code></pre>
    <p>{`Webpack will combine all JavaScript into a single
file called `}<inlineCode parentName="p">{`main.js`}</inlineCode>{`, so we'll need to reference that instead of `}<inlineCode parentName="p">{`index.js`}</inlineCode>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The `}<inlineCode parentName="p">{`--mode='development'`}</inlineCode>{` flag is so Webpack knows what configuration to build with.
Change this as needed for your project.`}</p>
    </blockquote>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`In `}<inlineCode parentName="li">{`index.html`}</inlineCode>{` change the `}<inlineCode parentName="li">{`<script>`}</inlineCode>{` that's using `}<inlineCode parentName="li">{`index.js`}</inlineCode>{` to `}<inlineCode parentName="li">{`main.js`}</inlineCode>{`.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script src="main.js"></script>
`}</code></pre>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">{`Build your website.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm run build
`}</code></pre>
    <p>{`You should now see a `}<inlineCode parentName="p">{`main.js`}</inlineCode>{` file in your `}<inlineCode parentName="p">{`dist`}</inlineCode>{` directory
containing minified JavaScript.`}</p>
    <div className="sprk-u-Measure">
  <img className="sprk-u-mbm" style={{
        "margin": "auto",
        "display": "block"
      }} src={bundledJs} alt="Minified JSON in main.js" />
    </div>
    <p>{`For more help setting up Webpack, check out the `}<a href="https://webpack.js.org/guides/getting-started/" target="_blank">{`Webpack Getting Started Guide`}</a>{`.`}</p>
    <h2>{`Installing Sass`}</h2>
    <a href="https://sass-lang.com/" target="_blank">Sass</a> is a tool that processes and compiles CSS. You'll need it to use Spark.
    <ol>
      <li parentName="ol">{`To install Sass, first run this command:`}</li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`npm install node-sass sass-loader css-loader mini-css-extract-plugin --save-dev
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`In the root directory of your project, create a new file called
`}<inlineCode parentName="li">{`webpack.config.js`}</inlineCode>{` and paste this code into it:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const MiniCssExtractPlugin = require('mini-css-extract-plugin');

module.exports = {
  module: {
    rules: [
      {
        test: /\\.s[ac]ss$/i,
        use: [
          MiniCssExtractPlugin.loader,
          'css-loader',
          'sass-loader',
        ],
      },
    ],
  },
  plugins: [
    new MiniCssExtractPlugin({
      filename: 'bundle.css'
    }),
  ],
};
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Now let’s write some CSS to compile. Create a new file in `}<inlineCode parentName="li">{`src`}</inlineCode>{`
called `}<inlineCode parentName="li">{`style.scss`}</inlineCode>{` and copy this CSS into it:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`body {
  background-color: green;
}
`}</code></pre>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`At the top of `}<inlineCode parentName="li">{`index.js`}</inlineCode>{`, add this reference to the `}<inlineCode parentName="li">{`scss`}</inlineCode>{`
file:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import "./style.scss"
`}</code></pre>
    <p>{`Now when Webpack compiles `}<inlineCode parentName="p">{`index.js`}</inlineCode>{`, this import line will include
your style file. It will be processed according to the rules
we set up in the webpack config in the previous step.`}</p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Add this reference to your new compiled CSS in the `}<inlineCode parentName="li">{`<head>`}</inlineCode>{` element of `}<inlineCode parentName="li">{`index.html`}</inlineCode>{`:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<link rel="stylesheet" href="bundle.css"></link>
`}</code></pre>
    <p>{`Now when you rebuild your site, you should see `}<inlineCode parentName="p">{`bundle.css`}</inlineCode>{` in the
`}<inlineCode parentName="p">{`dist`}</inlineCode>{` folder and your website should include your style:`}</p>
    <div className="sprk-u-Measure">
  <img className="sprk-u-mbm" style={{
        "margin": "auto",
        "display": "block"
      }} src={greenBg} alt="Hello Spark on a green a background." />
    </div>
    <p>{`For more help setting up Sass, check out the `}<a href="https://sass-lang.com/guide" target="_blank">{`Sass Getting Started Guide`}</a>{`.`}</p>
    <h2>{`Next Steps`}</h2>
    <p>{`You now have a development environment set up and ready to install Spark!
Check out `}<a parentName="p" {...{
        "href": "/installing-spark/html-installation"
      }}>{`Part 2 - Installation`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      